/**
 * Examples:
 *
 * Itinerary: /italy
 * Elsewhere: /argentina
 * Guide: /ghana
 */
import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useMonarch } from "@cohesion/monarch";
import { tagular } from "@cohesion/tagular";
import cn from "classnames";

import { PlaneFeature, Map } from "@icons";
import { GuideWordmark } from "@icons/logos/guide";

export function Driver({ ctx }) {
  const copy = {
    itinerary: {
      icon: Map,
      heading: ctx.itineraries[0]?.title,
      description:
        "Discover unique experiences, plus see all the classic sites in a new way.",
      href: `/itineraries/${ctx.itineraries[0]?.slug}`,
      cta: "Get Inspired",
    },
    elsewhere: {
      icon: PlaneFeature,
      heading: "Leave the planning to a local expert",
      description: `Experience the real ${ctx.title}. Let a local expert handle the planning for you.`,
      href: `${ctx.elsewhereData?.requestUrl}?utm_source=lonelyplanet&utm_medium=hubsdriver&utm_campaign=ewonly`,
      cta: "Get Inspired",
    },
    guide: {
      icon: GuideWordmark,
      heading: "Plan your trip with Guide, an AI travel planner!",
      description:
        "Create a personalized trip itinerary in seconds using artificial intelligence.",
      href: "https://www.guide.com?utm_source=lonelyplanet&utm_medium=hubsdriver&utm_campaign=guide",
      cta: "Create a trip",
    },
  };

  const monarch = useMonarch();
  const [mode, setMode] = useState(null);

  const itinerary = ctx.itineraries[0] || null;

  useEffect(() => {
    monarch(
      "rule",
      "80f57c2a-ceaf-48fd-b6df-ec506d377e69",
      {},
      {},
      (_, res) => {
        const { slug } = ctx;
        const show = Boolean(
          typeof res !== "undefined" && !res.destinationDriver.includes(slug)
        );

        switch (true) {
          case !itinerary &&
            ctx.elsewhere &&
            ctx.elsewhereData.driver !== false:
            setMode("elsewhere");
            break;

          case itinerary && ctx.elsewhere && ctx.elsewhereData.driver !== false:
            setMode(show ? "itinerary" : "elsewhere");
            break;

          case itinerary && !ctx.elsewhere:
            setMode(show ? "itinerary" : null);
            break;

          default:
            // Guide default unless explicitly excluded.
            setMode(show ? "guide" : null);
            break;
        }
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasRendered = useRef(false);

  useEffect(() => {
    if (!hasRendered.current && mode) {
      if (mode === "itinerary") {
        tagular("view", {
          webElement: {
            location: "PLANNINGDRIVER",
            elementType: "BUTTON",
            position: "0",
            text: mode,
            name: "DESTINATIONHUB",
          },
        });
      } else {
        tagular("product_view", {
          product: {
            location: `HUBS PLANNING DRIVER_${mode.toUpperCase()}`,
            text: copy[mode]?.cta,
            position: 0,
            brand: mode,
          },
        });
      }

      hasRendered.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  /**
   * Be patient. Wait on the switch case.
   */
  if (!mode) return null;

  const Icon = copy[mode].icon;

  return (
    <div className="bg-blue-100 rounded-md">
      <div className="p-10 text-center">
        <Icon
          className={cn("mx-auto", mode === "guide" ? "text-4xl" : "text-3xl", {
            "text-guide-purple": mode === "guide",
          })}
        />

        <header className="mt-4 mb-8">
          <h3 className="text-xl font-semibold leading-tight">
            {copy[mode].heading}
          </h3>
          <p className="mt-3 text-slate">{copy[mode]?.description}</p>
        </header>

        <a
          href={copy[mode].href}
          className="btn btn-primary block"
          target="_neue"
          rel="noopener noreferrer"
          onClick={() => {
            if (mode === "itinerary") {
              tagular("click", {
                actionOutcome: "EXTERNALLINK",
                outboundUrl: copy[mode]?.href,
                webElement: {
                  location: "PLANNINGDRIVER",
                  elementType: "BUTTON",
                  position: "0",
                  text: mode,
                  name: "DESTINATIONHUB",
                },
              });
            } else {
              tagular("product_click", {
                actionOutcome: "EXTERNALLINK",
                product: {
                  location: `HUBS PLANNING DRIVER_${mode.toUpperCase()}`,
                  text: copy[mode]?.cta,
                  position: 0,
                  brand: mode,
                },
                outboundUrl: copy[mode]?.href,
              });
            }
          }}
        >
          {copy[mode]?.cta}
        </a>
      </div>
    </div>
  );
}

Driver.propTypes = {
  ctx: PropTypes.shape({
    elsewhere: PropTypes.bool.isRequired,
    itineraries: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        slug: PropTypes.string.isRequired,
      })
    ).isRequired,
    title: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    elsewhereData: PropTypes.shape({
      driver: PropTypes.bool,
      requestUrl: PropTypes.string,
    }),
  }).isRequired,
};
